import React from 'react';

// import i18n from '../../i18n';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import CardEvent from '../CardEvent';
import styles from './EventListBlock.module.scss';

const EventListBlock = ({
    title,
    items = [],
    isSearchResult = false,
    isFirst = false,
    isLoading = false,
}) => {
    if (items.length === 0) return null;

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
    });

    const classes = classNames(styles['EventListBlock__Container'], {
        [styles['EventListBlock__Container--isVisible']]:
            inView || isSearchResult,
        [styles['EventListBlock__Container--isFirst']]: isFirst,
        [styles['EventListBlock__Container--isSearch']]: isSearchResult,
        [styles['EventListBlock__Container--Loading']]: isLoading,
    });

    return (
        <div
            ref={ref}
            className={styles['EventListBlock']}>
            <div className={classes}>
                <h2 className={styles['EventListBlock__Title']}>{title}</h2>
                <div className={styles['EventListBlock__List']}>
                    {isLoading &&
                        Array(8)
                            .fill()
                            .map((item, index) => (
                                <CardEvent
                                    id={index}
                                    size="medium"
                                    startDate={''}
                                    endDate={''}
                                    title={''}
                                    venueName={''}
                                    inSlider={true}
                                    isLoader={true}
                                    modifiers={['White']}
                                    key={index}
                                />
                            ))}
                    {!isLoading &&
                        items.map((item, index) => {
                            // console.log(item);
                            return (
                                <CardEvent
                                    {...item}
                                    id={index}
                                    startDate={new Date(item.startDate)}
                                    endDate={new Date(item.endDate)}
                                    inSlider={true}
                                    fromEventList={true}
                                    eventListHeadline={title}
                                    isSearchResult={isSearchResult}
                                    noEffect={isSearchResult && index < 4}
                                    modifiers={['White']}
                                    key={index}
                                />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

EventListBlock.propTypes = {
    items: PropTypes.array,
    title: PropTypes.string,
};

EventListBlock.defaultProps = {
    items: [],
    title: '',
};

export default EventListBlock;
